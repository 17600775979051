import React, { useContext } from 'react';
import { MemberContext } from "../AllMemberComponent";
import Logo from "../../../assets/images/logo.png"

function IdCar(props) {
    const {
        idData
    } = useContext(MemberContext)
    return (
        <>
            <div className="idCardHead"><img src={Logo} />Name:  Jamia Rahmania<br /><span>Government Registered</span></div>
            <div className="idCardBody">
                <h3>{idData.member_name}</h3>
                <strong>Guardian Name :</strong> {idData.guardian_name}<br />
                <strong>Mobile        :</strong> {idData.mobile}<br />
                <strong>Agent Id :    </strong>  {idData.agent_id}<br />
                <strong>start_year :</strong>  {idData.start_year}<br />
                <strong>Yearly Amount :</strong>  {idData.yearly_donation_amount}<br />
            </div>
            <div className="idCardfooter">Belerdhanyakuria, Gopmahal, Basirhat, North 24 Parganas, 743428</div>

        </>
    );
}

export default IdCar;
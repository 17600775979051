// const rootRoute = `${window.location.origin}/api/`
const rootRoute = "https://mapi.jamiarahmania.com/";
// const rootRoute = "http://localhost:2222/api/";

export const login = rootRoute + "user/login";
export const updateUserGeneralSetting = rootRoute + "user/updateUserGeneralSetting";
export const userPasswordChange = rootRoute + "user/userPasswordChange";
export const userLogOutFromAllDevice = rootRoute + "user/userLogOutFromAllDevice";
export const getAllUser = rootRoute + "user/getAllUser";
export const getAllRole = rootRoute + "user/getAllRole";
export const addUser = rootRoute + "user/addUser";
export const updateUserFromAdmin = rootRoute + "user/updateUserFromAdmin";
export const resetUserPasswordFromAdmin = rootRoute + "user/resetUserPasswordFromAdmin";

export const getAllAgent = rootRoute + "user/getAllAgent";
export const addAgent = rootRoute + "user/addAgent";
export const updateAgent = rootRoute + "user/updateAgent";
export const agentBalancePayment = rootRoute + "user/agentBalancePayment";
export const resetAgentPasswordFromAdmin = rootRoute + "user/resetAgentPasswordFromAdmin";
export const importNewAgent = rootRoute + "user/importNewAgent";
export const getAgentWalletHistory = rootRoute + "user/getAgentWalletHistory";
export const getAgentCollectionHistory = rootRoute + "user/getAgentCollectionHistory";

export const getTotalDue = rootRoute + "member/getTotalDue";
export const getAllMember = rootRoute + "member/getAllMember";
export const addMember = rootRoute + "member/addMember";
export const updateMember = rootRoute + "member/updateMember";
export const donationCollection = rootRoute + "member/donationCollection";
export const getAgentWiseMember = rootRoute + "member/getAgentWiseMember";
export const getAllMembersForExport = rootRoute + "member/getAllMembersForExport";
export const importMember = rootRoute + "member/importMember";
